import React from "react"
import { Link } from "gatsby"


const header = ({ title }) => (
    <h3>
        <Link
            style={{
                boxShadow: `none`,
                color: `inherit`,
            }}
            to={`/`}
        >
            {title}
        </Link>
        <Link
            style={{
                boxShadow: `none`,
                color: `inherit`,
            }}
            to={`/blog`}
        >
            Blog
            </Link>
        <Link
            style={{
                boxShadow: `none`,
                color: `inherit`,
            }}
            to={`/about`}
        >
            About
            </Link>
    </h3>
);

export default header;