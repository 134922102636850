import { graphql, StaticQuery } from 'gatsby';
import React from 'react';
import { Helmet } from 'react-helmet-async';
import { Box, Flex } from 'rebass';
import smoothscroll from 'smoothscroll-polyfill';
import { createGlobalStyle, ThemeProvider } from 'styled-components';
import defaultTheme from '../utils/theme';
import Header from './header';

if (typeof window !== 'undefined') {
    smoothscroll.polyfill();
}

const GlobalStyle = createGlobalStyle`
  html {
    all: initial;
    color: ${({ theme }: any) => theme.colors.darkgray};
    font-family: ${({ theme }: any) => theme.fonts.sans};
    scroll-behavior: smooth;
  }

  body {
    margin: 0;

    /* Prevent adjustments of font size after orientation changes in iOS */
    text-size-adjust: 100%;
  }

  main {
    margin: 4% auto;
    
    @media (min-width: ${({ theme }: any) => theme.breakpoints[0]}px) {
        width: 60%;
        max-width: ${({ theme }: any) => theme.breakpoints[0]}px;
    }        
    // smaller screens   
    @media (max-width: ${({ theme }: any) => theme.breakpoints[0]}px) {
        width: 90%;
    }          
  }
  
  h3 {
    font-weight: 500;
    font-family: sans-serif;
    font-size: 1.2rem;
    display: flex;
    justify-content: space-between;
    margin: 4px 0;      
    // smaller screens   
    @media (max-width: ${({ theme }: any) => theme.breakpoints[0]}px) {
        width: 100%;
    }          
  }

  h3 a {
    box-shadow: none;
  }

  h3 small {
    font-style: italic;
    color: #666;
    font-size: 100%;
  }

  article {
      margin: 0
  }

  p {
    line-height: 1.5;
  }
`;

interface Props {
    children: React.ReactNode;
}

const Layout = ({ children, title }: Props) => (
    <ThemeProvider theme={defaultTheme}>
        <Flex flexDirection="column" css="min-height: 100vh;">
            <GlobalStyle />


            <main>
                <Header title={title} />

                {children}
                <footer style={{
                    marginTop: 40
                }}>
                    © {new Date().getFullYear()}, Built with
        {` `}
                    <a href="https://www.gatsbyjs.org">Gatsby</a>
                </footer>
            </main>
        </Flex>
    </ThemeProvider>
);

export default Layout;